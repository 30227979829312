// extracted by mini-css-extract-plugin
export var sectionWrapper = "about-module--section-wrapper--9288d";
export var sectionContent = "about-module--section-content--a9bf3";
export var firstSection = "about-module--first-section--32b8b";
export var sectionText = "about-module--section-text--32f8e";
export var sectionImageWBackground = "about-module--section-image-w-background--6f9ed";
export var imageOnBackground = "about-module--image-on-background--4c09a";
export var callOutBlue = "about-module--call-out-blue--f8278";
export var link = "about-module--link--30249";
export var chevron = "about-module--chevron--d0a70";
export var scrollUp = "about-module--scroll-up--e9dde";