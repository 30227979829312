import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as about from './../css/about.module.css';
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next";
import CallToAction from "../components/CallToAction";
import { Link, animateScroll as scroll } from 'react-scroll';

const AboutMe = () => {
  const { t } = useTranslation('about');
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
  <Layout>
    <Seo title={t("seoTitle")} />
    
    <section id={about.firstSection}>
    <div className={about.sectionWrapper}>
    <h2>{t("section1Heading")}</h2>
      <div className={about.sectionContent}>
        
        <div className={about.sectionImageWBackground}>
          <StaticImage
            className={about.imageOnBackground}
            src="../images/Esa_5.jpg"
            height={220}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Esa Löytynoja"
          />  
        </div>
        
        <div className={about.sectionText}>
          
          <p>{t("section1P1")}</p>
          <p>{t("section1P2")}</p>
          <p>{t("section1P3")}<a href="https://www.linkedin.com/in/esaloytynoja/" target="_blank" rel="noopener" title="LinkedIn Esa Löytynoja" className={about.link} >LinkedIn</a>.</p>
        </div>
        
      </div>
      <Link 
            className={about.chevron}
            to="second_section"
            spy={true}
            smooth={true}
            offset={-120}
            duration={1000}>
            <StaticImage
              src="../images/chevron_red.png"
              formats={["auto", "webp", "avif"]}
              alt="Arrow down"
            />
          </Link>
        </div>
    </section>

    <section>
      <div className={about.sectionWrapper}>
        <div className={about.sectionContent}>
          <div className={about.sectionText}>
              <h2 id="second_section">{t("section2Heading")}</h2>
              <p>{t("section2P1")}</p>
              <p>{t("section2P2")} </p>
              <div className={about.callOutBlue}>
                <p>{t("section2CallOut")}</p>
              </div>
          </div>
        </div>
      </div>
    </section>
    
    <CallToAction />

    <div className={about.scrollUp}>
        <button
         onClick={scrollToTop}>
          <StaticImage
                  src="../images/arrowup_grey.png"
                  height={50}
                  formats={["auto", "webp", "avif"]}
                  alt="Back to top"
                />
        </button>
      </div>

  </Layout>
)}

export default AboutMe
